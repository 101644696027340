import React from 'react'
import { graphql } from 'gatsby'
import { getRichText, notEmpty } from 'helpers'

import { Grid } from 'system'
import { Subheading, HTMLContent, ContentBox } from 'src/components'

export const ProjectBodyText = ({ heading, label, textHTML, ...props }) => {
  return (
    <ContentBox {...props}>
      {(heading || label) && (
        <Grid
          mbScale="m"
          gridTemplateColumns={['1fr', 'auto 1fr']}
          alignItems="center"
          gridGapScale="s"
        >
          {heading && <Subheading color="gray.10">{heading}</Subheading>}
          {label && (
            <Subheading
              pyScale="t-"
              pxScale="s"
              bg="teal.40"
              width="min-content"
              css={{ whiteSpace: 'nowrap' }}
              color="white"
            >
              {label}
            </Subheading>
          )}
        </Grid>
      )}

      {textHTML && (
        <HTMLContent
          html={textHTML}
          componentOverrides={{
            h1: (Comp) => (props) => (
              <Comp {...props} fontSizeScale="b" mbScale="t" />
            ),
          }}
        />
      )}
    </ContentBox>
  )
}
ProjectBodyText.mapDataToProps = ({ data }) => {
  const primary = data?.primary

  return {
    heading: notEmpty(primary?.heading?.text),
    label: notEmpty(primary?.label?.text),
    textHTML: getRichText(primary?.text),
  }
}

export const fragment = graphql`
  fragment ProjectBodyText on Query {
    prismicProject(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicProjectBodyText {
            id
            primary {
              heading {
                text
              }
              label {
                text
              }
              text {
                text
                html
              }
            }
          }
        }
      }
    }
  }
`
