import { graphql } from 'gatsby'

// 1. Import your slice
import { ProjectBodyText } from './ProjectBodyText'
import { ProjectBodyImages } from './ProjectBodyImages'
import { ProjectBodyContent } from './ProjectBodyContent'

// 2. Add your slice
export const slicesMap = {
  ProjectBodyText,
  ProjectBodyImages,
  ProjectBodyContent,
}

// 3. Add your slice fragment
export const fragment = graphql`
  fragment ProjectPageBody on Query {
    ...ProjectBodyText
    ...ProjectBodyImages

    # The following slices do not have fragments:
    #...ProjectBodyContent
  }
`
