import React from 'react'
import { graphql } from 'gatsby'

import { Image, ImageContainer, Grid, AspectRatio } from 'system'
import { ContentBox } from 'src/components'

export const ProjectBodyImages = ({ children, ...props }) => {
  const numImages = Math.min(2, React.Children.count(children))

  return (
    <ContentBox {...props}>
      <Grid
        gridGapScale="m"
        gridTemplateColumns={['1fr', `repeat(${numImages}, 1fr)`]}
      >
        {children}
      </Grid>
    </ContentBox>
  )
}

ProjectBodyImages.mapDataToProps = ({ data }) => ({
  children: data?.items?.map((item) => {
    const image = item?.image
    const imageAlt = image?.alt
    const imageFluid = image?.fluid

    return (
      <ImageContainer>
        <AspectRatio x={4} y={3}>
          <Image fluid={imageFluid} alt={imageAlt} />
        </AspectRatio>
      </ImageContainer>
    )
  }),
})

export const fragment = graphql`
  fragment ProjectBodyImages on Query {
    prismicProject(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicProjectBodyImages {
            id
            items {
              image {
                alt
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
