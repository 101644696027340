import React from 'react'
import MapSlicesToComponents from '@walltowall/react-map-slices-to-components'
import { propPairsEq, notEmpty } from 'helpers'

import { validateCoordinateTuple, coordinateObjToTuple } from 'src/utils'

import { Box, Grid } from 'system'
import {
  OffsetBoundedBox,
  IconLinkList,
  ProjectIntro,
  StaticAirportMap,
  KeyValueDetail,
  Rule,
  AirportGraphic,
} from 'src/components'

import { slicesMap } from 'src/slices/ProjectBody'

export const ProjectBodyContent = ({
  projectData,
  airportData,
  arcGisFeature,
  list,
  ...props
}) => {
  const downloads = notEmpty(
    projectData?.downloads.filter((download) => download?.file?.url),
  )
  const related_projects = notEmpty(
    projectData?.related_projects.filter(
      (related_project) =>
        related_project?.project?.document ||
        related_project?.manual_project_name?.text,
    ),
  )
  const zoneDoc = projectData?.zone?.document
  const airportUID = projectData?.airport?.document?.uid
  const projectFallbackCoordinates = validateCoordinateTuple(
    coordinateObjToTuple(projectData?.fallback_coordinates),
  )
    ? coordinateObjToTuple(projectData?.fallback_coordinates)
    : undefined
  const mapCoordinates =
    arcGisFeature?.polylabel ??
    arcGisFeature?.centroid ??
    projectFallbackCoordinates
  const geometryColor = projectData?.status?.document?.data?.color

  return (
    <OffsetBoundedBox
      as="section"
      bg="teal.90"
      innerProps={{ pyScale: 0 }}
      {...props}
    >
      <ProjectIntro projectData={projectData} airportData={airportData} />

      <Rule
        variant="extendRight"
        height="1px"
        ml={['-1.5rem', 'calc(-1 * (100vw - 100%) / 2)']}
      />

      <Grid
        gridTemplateColumns={['1fr', null, '2fr 1fr']}
        gridColumnGapScale="l"
        ptScale={notEmpty(list) ? 0 : 'm'}
      >
        <Box>
          <MapSlicesToComponents list={list} map={slicesMap} />
        </Box>

        <Grid
          alignSelf="start"
          as="aside"
          gridGapScale="m"
          pbScale="l"
          ptScale={[0, null, 'l']}
          maxWidth="28rem"
        >
          <AirportGraphic
            code={airportData?.code?.text}
            name={airportData?.full_name?.text}
            mb={2}
          />

          <KeyValueDetail variant="tertiary" label="Project No.">
            {projectData?.project_number?.text}
          </KeyValueDetail>

          <KeyValueDetail variant="tertiary" label="Zone">
            {zoneDoc?.data?.name?.text}
          </KeyValueDetail>

          {related_projects && (
            <KeyValueDetail
              label={`Related Project${related_projects.length > 1 ? 's' : ''}`}
              variant="tertiary"
            >
              <IconLinkList>
                {related_projects?.map((related_project) => (
                  <IconLinkList.Item
                    icon={
                      related_project?.project?.document?.url
                        ? 'project'
                        : false
                    }
                    href={related_project?.project?.document?.url}
                  >
                    {related_project?.manual_project_name?.text ||
                      related_project?.project?.document?.data?.title?.text}
                  </IconLinkList.Item>
                ))}
              </IconLinkList>
            </KeyValueDetail>
          )}

          {downloads && (
            <>
              <Rule height="1px" />
              <KeyValueDetail variant="tertiary">
                <IconLinkList icon="download">
                  {downloads?.map((file) => (
                    <IconLinkList.Item
                      key={file?.file_label?.text}
                      href={file?.file?.url}
                      target="_blank"
                    >
                      {file?.file_label?.text || 'Download file'}
                    </IconLinkList.Item>
                  ))}
                </IconLinkList>
              </KeyValueDetail>
            </>
          )}
        </Grid>

        <StaticAirportMap
          airportUID={airportUID}
          geometry={arcGisFeature?.geometry}
          geometryColor={geometryColor}
          coordinates={mapCoordinates}
          markerType="project"
          gridColumn="1 / -1"
          mrScale={[0, null, '-m']}
          height={['75vw', '50vw']}
          maxHeight="50rem"
        />
      </Grid>
    </OffsetBoundedBox>
  )
}

ProjectBodyContent.mapDataToProps = ({
  data,
  context,
  previousContext,
  nextContext,
}) => ({
  nextSharesBg: propPairsEq('bg', context, nextContext),
  previousSharesBg: propPairsEq('bg', context, previousContext),
  projectData: data?.projectData,
  airportData: data?.airportData,
  arcGisFeature: data?.arcGisFeature,
  list: data?.list,
})

ProjectBodyContent.mapDataToContext = () => ({
  bg: 'teal.90',
})
